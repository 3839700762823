import cx from "classnames";
import { Logo } from "components/Logo";
import { SectionHeading } from "components/SectionHeading";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { getImage } from "libs/getImage";
import * as React from "react";

export default function Characters({
	data,
}: PageProps<Queries.CharactersQuery>) {
	const { t, language } = useI18next();

	return (
		<div className="pt-20 lg:pt-0">
			<Logo positioned />
			<div className="grid max-h-[50vh]">
				<div className="col-span-full row-span-full -z-10">
					<div className="block text-center lg:hidden">
						<StaticImage
							src="../images/character-hero-m.png"
							alt=""
						/>
					</div>
					<div className="hidden text-center lg:block">
						<StaticImage
							src="../images/character-hero.jpeg"
							alt=""
						/>
					</div>
				</div>
				<div className="col-span-full row-span-full pt-8 text-center lg:pt-36">
					<SectionHeading as="h1">{t("nav.characters")}</SectionHeading>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-y-7 px-2 pb-80 mx-auto max-w-7xl lg:gap-y-28 lg:grid-cols-3 lg:gap-12">
				{data.characters.edges.map(({ node }) => (
					<section key={node.id}>
						<Link to={`/characters/${node.frontmatter?.slug}`} className="grid">
							<GatsbyImage
								image={getImage(node.frontmatter?.avatar)}
								alt="Thumbnail"
								className="col-span-full row-span-full -z-10"
							/>
							<div className="flex col-span-full row-span-full">
								<div className="w-2/3 pb-[3vw] lg:pb-[6%] mt-auto text-right text-[3vw] leading-tight">
									<div
										className={cx(
											"text-notice lg:text-xl drop-shadow-[0_0_5px_black]",
											{ invisible: language === "en" }
										)}
									>
										{node.frontmatter?.armor?.title}
									</div>
									<div className="font-bold lg:text-xs">
										{node.frontmatter?.armor?.en}
									</div>
									<h3 className="font-bold lg:text-2xl">
										{node.frontmatter?.title}
									</h3>
								</div>
							</div>
						</Link>
					</section>
				))}
			</div>
		</div>
	);
}

export const query = graphql`
	fragment CharacterItem on MarkdownRemark {
		id
		frontmatter {
			title
			slug
			armor {
				title
				en
			}
			avatar {
				childImageSharp {
					gatsbyImageData(
						sizes: "(max-width: 768px) 50vw, 471px"
						placeholder: BLURRED
					)
				}
			}
		}
	}
	query Characters($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					...Translation
				}
			}
		}
		characters: allMarkdownRemark(
			filter: {
				frontmatter: { type: { eq: "character" } }
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___order, order: ASC }
		) {
			edges {
				node {
					...CharacterItem
				}
			}
		}
	}
`;

export { Head } from "components/GCSRewriteURL";
